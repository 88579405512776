<template>
  <ion-page class="ion-page">
    <event-form :loading="loading" :error="error" @submit="createEventForward" :event-type="EventTypeEnum.TRAINING"/>
  </ion-page>
</template>

<script>
  import { CREATE_EVENT } from "@/graphql/mutations";
  import EventForm from '../../components/forms/EventForm.vue';
  import { EventTypeEnum } from "@/graphql/enums";

  export default {
      components: {
        EventForm,
      },
      data() {
        return {
          EventTypeEnum,
          submitData: null,
          loading: false,
          error: null,
        }
      },
      methods: {
        async createEventForward(data) {
          this.submitData = data;

          this.loading = true;
          this.error = null;

          try {
            const response = await this.$apollo.mutate({
              mutation: CREATE_EVENT,
              variables: {
                data: this.submitData
              },
            });

            this.$router.push({ name: this.$routeNames.EVENT, params: { id: response.data.createEvent.id }});
          } catch(error) {

            this.error = error;
          } finally {
            this.loading = false;
          }
        },
      }
    }
</script>
